@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
html {
    font-family: 'Nunito', sans-serif;
}

.button-shadow {
    box-shadow: -1px 1px 1px 0 rgb(0 0 0);
}

.button-shadow:active {
    box-shadow: inset -1px 1px 1px 0 rgb(0 0 0);
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

@layer components {
    .hover-lift {
        @apply transition hover:scale-105 hover:shadow-xl;
    }

    .html-display ul {
        @apply list-disc ml-5 px-2 pt-2
    }

    .markup p {
        margin-bottom: 1rem;
    }

    .markup ul {
        list-style-type: disc;
        margin-left: 1rem;
        padding: .25rem .5rem 0 .5rem;
    }

    .markup ul ul {
        list-style-type: circle;
    }
}
